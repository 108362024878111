body {
  background-color: #f8f9fa;
}

.login-container {
  max-width: 400px;
  margin: auto;
  margin-top: 100px;
}

.login-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.btn-login {
  width: 100%;
}


  /* FILEPATH: /home/yashvarshney/Documents/GitHub/mmf-admin-crm-fe/src/components/FetchDetails.css */

                        /* Style for the refund button */
                        .refund-button {
                          background-color: #dc3545;
                          color: #fff;
                          border: none;
                          padding: 0.5rem 1rem;
                          border-radius: 0.25rem;
                          cursor: pointer;
                          transition: background-color 0.3s ease;
                        }

                        .refund-button:hover {
                          background-color: #c82333;
                        }

                        .refund-button:focus {
                          outline: none;
                          box-shadow: none;
                        }

                        .refund-button:active {
                          background-color: #bd2130;
                        }